import { PriceLockup as AlliumPriceLockup } from '@telus-uds/components-web'

import { SpacerProps } from '../Spacer/Spacer'
import { TextRenderer } from '../../../utils/text/TextRenderer'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'

export type PriceLockupProps = {
  topText?: string
  price: string
  size?: 'Small' | 'Medium' | 'Large'
  rateText?: string
  bottomText?: string
  signDirection?: 'Right' | 'Left'
  strikethrough?: boolean
  a11yText?: string
  spacer?: SpacerProps
}

const PriceLockup = ({
  topText = '',
  price = '',
  size = 'Medium',
  rateText = '',
  bottomText,
  signDirection = 'Left',
  strikethrough = false,
  a11yText = '',
  spacer = undefined,
}: PriceLockupProps) => {
  return (
    <>
      {renderSpacer(spacer)}
      <AlliumPriceLockup
        topText={<TextRenderer>{topText}</TextRenderer>}
        price={price}
        size={size.toLowerCase()}
        rateText={<TextRenderer>{rateText}</TextRenderer>}
        bottomText={bottomText ? <TextRenderer>{bottomText}</TextRenderer> : null}
        signDirection={signDirection.toLowerCase()}
        strikeThrough={strikethrough}
        a11yText={a11yText}
        data-testid={'price-lockup-testid'}
      />
    </>
  )
}

export default PriceLockup
